html {
  font-size: 70%;
}

body {
  font-size: 16px;
}

// Print
#print-only {
  display: none;
}
@media print {
  #print-only {
    display: block;
  }

  html, body { height: initial !important; overflow: initial !important; }
}

.Polaris-Frame {
  background-color: #f9f9f9;

  .Polaris-Navigation {
    background-color: #f9f9f9;
  }

  .Polaris-Card {
    box-shadow: 0 0 0 1px rgba(63,63,68,0),0px 0px 5px 1px rgba(63,63,68,0.15);
  }

  .Polaris-Frame__TopBar {
    border-bottom: 1px solid rgba(128, 128, 128, 0.25);
  }
}
